export function signupSiteName(databaseName){
    switch(databaseName){
        case 'smg':
            return "sharemygame.com"
        case 'simmer':
        default:
            return "SIMMER.io";
    }
}

export function signupSiteUrl(databaseName) {
    switch (databaseName) {
        case 'smg':
            return "https://sharemygame.com"
        case 'simmer':
        default:
            return "https://simmer.io";
    }
}

export function getCurrentSite(){
    // todo midpri whitelabel
    return 'simmer';
}